.background_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.win_img {
  position: absolute;
  width: 250px;
  right: calc(50% - 125px);
  top: 170px;
  transform: rotate(15deg);
}

.lose_img {
  position: absolute;
  width: 250px;
  right: calc(50% - 125px);
  top: 170px;
  transform: rotate(15deg);
}

.boom_img {
  position: absolute;
  width: 260px;
  right: calc(50% - 130px);
  top: 180px;
  transform: rotate(15deg);
}
