.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.rock_right_hand_img {
  width: 350px;
  margin-right: -15px;
  transition: 0.2s all ease-in;
}

.scissors_right_hand_img {
  width: 400px;
  margin-top: 25px;
  margin-right: -15px;
  transition: 0.2s all ease-in;
}

.paper_right_hand_img {
  width: 430px;
  margin-right: -40px;
  transition: 0.2s all ease-in;
}

.player_info {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
}

.person {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  border: 4px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--red);
}

.person > svg {
  width: 50px;
  height: 50px;
  color: white;
}

.star_container {
  margin-right: 20px;
}

.star {
  color: white;
  width: 50px !important;
  height: 50px !important;
  margin: 0 4px;
}

.active_star {
  color: var(--yellow) !important;
}

.opponent_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.opponent_card {
  width: 120px;
  height: 120px;
  border-radius: 12px;
  border: 4px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--red);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotatey(360deg);
  }
}

.opponent_card > svg {
  width: 110px;
  height: 110px;
  color: white;
}

.opponent_text {
  font-size: 18px;
  color: white;
  margin: 8px 0;
}
