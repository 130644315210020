.left {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.right {
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

.logo {
  width: 500px;
}

.paper_hand {
  width: 500px;
  rotate: 20deg;
  position: absolute;
  top: 0;
  right: -50px;
  z-index: -1;
}

.rock_hand {
  position: absolute;
  bottom: -60px;
  left: -175px;
  width: 400px;
  rotate: -50deg;
  z-index: -1;
}

.btn_container {
  display: flex;
  flex-direction: column;
  margin: 30px 40px;
}
