.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rock_left_hand_img {
  width: 350px;
  margin-left: -15px;
  transition: 0.2s all ease-in;
}

.scissors_left_hand_img {
  width: 400px;
  margin-top: 25px;
  margin-left: -15px;
  transition: 0.2s all ease-in;
}

.paper_left_hand_img {
  width: 430px;
  margin-left: -40px;
  transition: 0.2s all ease-in;
}

.player_info {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
}

.person {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  border: 4px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--orange);
}

.person > svg {
  width: 50px;
  height: 50px;
  color: white;
}

.star_container {
  margin-left: 20px;
}

.star {
  color: white;
  width: 50px !important;
  height: 50px !important;
  margin: 0 4px;
}

.active_star {
  color: var(--yellow) !important;
}
