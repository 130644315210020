@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

body {
  margin: 0;
  padding: 0;
  margin: 0;
  font-family: "Anton", sans-serif;
  user-select: none;
}

a {
  color: inherit;
  text-decoration: none;
}

:root {
  --box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  --light-purple: #2e1f8663;
  --yellow: #ffdf00;
  --orange: #e48c11;
  --red: #d92212;
  --purple: #955cd9;
  --green: #01a049;
}
