.btn {
  position: relative;
  outline: none;
  border: none;
  width: 250px;
  height: 80px;
  border-radius: 25px;
  background: transparent;
  font-size: 20px;
  font-family: inherit;
  text-transform: capitalize;
  color: white;
  cursor: pointer;
  margin-bottom: 10px;
}

.btn_background_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.btn:hover {
  scale: 1.02;
}
