.main {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.background_img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.container {
	width: 1000px;
	height: 550px;
	background-color: var(--light-purple);
	border-radius: 30px;
	box-shadow: var(--box-shadow);
	z-index: 2;
	display: flex;
	overflow: hidden;
	position: relative;
}
